body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F7F5F4;
}
.link {
  color: #0A6AA7;
}
.link:hover {
  text-decoration: none;
}
.blur {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}
.must14 {
  font-size: 14px !important;
}